/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  // FormattedMessage, 
  injectIntl
} from "react-intl";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import * as TYPE from '../../../../../app/lib/contrant'


function AsideMenuList(props) {
  const { listUserLoginPermission } = useSelector(state => ({
    listUserLoginPermission: state.authReducer.listUserLoginPermission,
  }), shallowEqual)


  let { layoutProps, intl } = props
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (

    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Dashboard management */}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.DASHBOARD' })}</span>
          </NavLink>
        </li>

        {/* Content management */}
        {/*begin::1 Level*/}


        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/content",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.CONTENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.CONTENT' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/content/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/content/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_CONTENT' })}</span>
                </NavLink>
              </li>}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/content/tag/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/content/tag/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_TAG_CONTENT' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/content/category"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/content/category/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_POST_CATEGORY' })}</span>
                </NavLink>
              </li>}

            </ul>
          </div>
        </li>



        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/project",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/project">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PROJECT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PROJECT' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <li
                className={`menu-item ${getMenuItemActive(
                  "/project/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/project/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_PROJECT' })}</span>
                </NavLink>
              </li>}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <li
                className={`menu-item ${getMenuItemActive(
                  "/project/tag/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/project/tag/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_TAG_PROJECT' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {/* {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/content/category"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/content/category/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_POST_CATEGORY' })}</span>
                </NavLink>
              </li>} */}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/job",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.JOB' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.JOB' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_JOB) && <li
                className={`menu-item ${getMenuItemActive(
                  "/job/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/job/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_JOB' })}</span>
                </NavLink>
              </li>}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/service",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Cooking/Dinner.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.SERVICE' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.JOB' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_DICH_VU) && <li
                className={`menu-item ${getMenuItemActive(
                  "/service/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/service/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_SERVICE' })}</span>
                </NavLink>
              </li>}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/requestForm",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.REQUEST_FORM' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.REQUEST_FORM' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_JOB) && <li
                className={`menu-item ${getMenuItemActive(
                  "/request/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/request/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_REQUEST_FORM' })}</span>
                </NavLink>
              </li>}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}



        {/* Admin management */}
        {/*begin::1 Level*/}
        {<li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/admin",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/admin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ADMIN_MANAGEMENT' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PERMISSION_LIST' })}</span>
                </span>
              </li>

              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_VAI_TRO) && <li
                className={`menu-item ${getMenuItemActive(
                  "/admin/role"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin/role">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ROLE_LIST' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_NHOM_QUYEN) && <li
                className={`menu-item ${getMenuItemActive(
                  "/admin/group-permission"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin/group-permission">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.GROUP_PERMISSION' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_QUYEN) && <li
                className={`menu-item ${getMenuItemActive(
                  "/admin/permission"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin/permission">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PERMISSION_LIST' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_ADMIN) && <li
                className={`menu-item ${getMenuItemActive(
                  "/admin/list-user"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin/list-user">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ADMIN' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>}
        {/*end::1 Level*/}

        {/* User Guest management */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_NGUOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/user/list-user"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user/list-user">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER' })}</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/image",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Media-library1.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.IMAGE' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.IMAGE' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_HINH_ANH) && <li
                className={`menu-item ${getMenuItemActive(
                  "/image/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/image/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.IMAGE.LIST' })}</span>
                </NavLink>
              </li>}
            </ul>
          </div>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/member",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/member">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MEMBER' })}</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MEMBER' })}</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {listUserLoginPermission.includes(TYPE.QUAN_LY_HINH_ANH) && <li
                className={`menu-item ${getMenuItemActive(
                  "/member/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/member/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MEMBER' })}</span>
                </NavLink>
              </li>}

              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {/* {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <li
                className={`menu-item ${getMenuItemActive(
                  "/content/category"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/content/category/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.LIST_POST_CATEGORY' })}</span>
                </NavLink>
              </li>} */}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {
          // listUserLoginPermission.includes(TYPE.QUAN_LY_PROFILE) && 
          <li
            className={`menu-item ${getMenuItemActive(
              "profile"
              , false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/profile">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
              <span className="menu-text">{"Quản lý profile công ty"}</span>
            </NavLink>
          </li>}
      </ul>
    </>
  );
}
export default withRouter(injectIntl(AsideMenuList));

import React from "react";
import { css } from '@emotion/css'
import { Button } from 'antd'

export default function CustomButton(props) {
    const {
        className, onClick, type, htmlType, loading, disabled, btnWidth, btnHeight, btnHoverBackground,
        children, title, btnPadding, btnColor, btnBgColor, btnRadius, isPrevent, onKeyDown, style
    } = props;
    return (
        <Button
            type={type}
            onClick={(e) => {
                if (isPrevent) {
                    e.stopPropagation();
                }
                if (onClick) {
                    onClick();
                }
            }}
            htmlType={htmlType}
            loading={loading}
            disabled={disabled}
            onKeyDown={onKeyDown}
            style={style}
            className={`customButton ${css`
            border-radius: ${btnRadius ? btnRadius : '8px'};
            width: ${btnWidth};
            height: ${btnHeight};
            box-shadow: #0000000f;
            padding: ${btnPadding};
            background-color: ${btnBgColor};
            color: ${btnColor};

            &:hover,
            &:focus {
            background-color: ${btnHoverBackground};
            color: ${btnColor};
        }
      `} ${className}`}
        >
            {children}{title}
        </Button>
    )
}
import { Types } from './types'

const initialState = {
    dataRequest: {
        rows: [],
        paging: {}
    },
}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_REQUEST:
            return {
                ...newState,
                dataRequest: action.payload
            }
        default:
            return { ...newState }
    }
}
export default reducer

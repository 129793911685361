///////////////////////PERMISSION///////////////////////////////////
export const QUAN_LY_ADMIN = "quan-ly-admin"
export const QUAN_LY_VAI_TRO = "quan-ly-vai-tro"
export const QUAN_LY_QUYEN = "quan-ly-quyen"
export const QUAN_LY_NHOM_QUYEN = "quan-ly-nhom-quyen"

export const TRANG_CA_NHAN_ADMIN = "trang-ca-nhan-admin"
export const DOI_MAT_KHAU = "doi-mat-khau"

export const QUAN_LY_NGUOI_DUNG = "quan-ly-nguoi-dung"
export const QUAN_LY_CHI_TIET_NGUOI_DUNG = "quan-ly-chi-tiet-nguoi-dung"


export const QUAN_LY_NOI_DUNG = "quan-ly-noi-dung"
export const QUAN_LY_JOB = "quan-ly-job"
export const QUAN_LY_YEU_CAU = "quan-ly-yeu-cau"
export const QUAN_LY_DICH_VU = "quan-ly-dich-vu"
export const QUAN_LY_DU_AN = "quan-ly-du-an"
export const QUAN_LY_HINH_ANH = "quan-ly-hinh-anh"
export const QUAN_LY_NHAN_SU = "quan-ly-nhan-su"
export const QUAN_LY_PROFILE = "quan-ly-profile-cong-ty"

///////////////////////END PERMISSION///////////////////////////////////
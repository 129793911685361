import { Types } from './types'

const initialState = {
    dataGroupPermission: {
        paging: {},
        rows: [],
    },
    dataPermission: {
        paging: {},
        rows: [],
    },
    dataRole: {
        paging: {},
        rows: [],
    },
    dataSetPermission: [],
}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_GROUP_PERMISSION:
            return {
                ...newState,
                dataGroupPermission: action.payload
            }
        case Types.SAVE_LIST_PERMISSION:
            return {
                ...newState,
                dataPermission: action.payload
            }
        case Types.SAVE_LIST_ROLE:
            return {
                ...newState,
                dataRole: action.payload
            }
        case Types.SAVE_LIST_SET_PERMISSON:
            return {
                ...newState,
                dataSetPermission: action.payload
            }
        default:
            return { ...newState }
    }
}
export default reducer

import { fetchApi } from "../../app/lib/api"
import { Types } from './types'
import { checkErrorCode } from '../auth/actions'
import { LIMIT_PAGE } from '../../lib/common'

export const actionGetListConfig = (payload) => async (dispatch, getState) => {
    try {
        dispatch(actionLoading(true))
        const { page } = payload
        payload.limit = LIMIT_PAGE

        let response = await fetchApi(`/admin/config/list`, "get", payload)

        if (response.code !== 200) {
            dispatch(actionLoading(false))
            return checkErrorCode(response?.code, response?.message)
        }
        response.data = {
            ...response.data,
            paging: {
                page: page || 1,
                total: response?.data?.total || 0,
                count: Math.ceil((response?.data?.total) / LIMIT_PAGE),
                limit: LIMIT_PAGE
            }
        }
        await dispatch(actionSaveListConfig(response.data))
        dispatch(actionLoading(false))
        return response.data
    } catch (error) {
        alert(error || error?.message)
    }
}
export const actionDeleteConfig = (payload) => async (dispatch, getState) => {
    const { dataPush, filterModal } = payload

    try {
        dispatch(actionLoading(true))
        let response = await fetchApi(`/admin/config/del`, "post", dataPush)

        if (response.code !== 200) {
            dispatch(actionLoading(false))
            return checkErrorCode(response?.code, response?.message)
        }
        //thêm
        if (!payload.id) {
            await dispatch(actionGetListConfig(filterModal))
        }
        //sửa
        else {
            const state = getState()
            const dataPost = state?.postReducer?.dataPost
            let newData = { ...dataPost }

            const index = newData.rows.findIndex(it => it.id === payload.id)
            newData.rows[index] = response.data
            await dispatch(actionSaveListConfig(filterModal))
        }
        dispatch(actionLoading(false))
        return response.code
    } catch (error) {
        alert(error || error?.message)
    }
}

export const actionUpdateConfig = (payload) => async (dispatch, getState) => {
    const { filterModal, dataPush } = payload
    try {
        dispatch(actionLoading(true))

        let response = await fetchApi(`/admin/config/update`, "post", dataPush)
        if (response.code !== 200) {
            dispatch(actionLoading(false))
            return checkErrorCode(response?.code, response?.message)
        }

        await dispatch(actionGetListConfig(filterModal))
        dispatch(actionLoading(false))
        return response.code
    } catch (error) {
        alert(error || error?.message)
    }
}
export const actionUpdateConfigCMS = (payload) => async (dispatch, getState) => {
    const { filterModal, dataPush } = payload
    try {
        dispatch(actionLoading(true))
        let response = await fetchApi(`/admin/config/update-cms`, "post", payload)
        if (response.code !== 200) {
            dispatch(actionLoading(false))
            return checkErrorCode(response?.code, response?.message)
        }

        // await dispatch(actionGetListConfig(filterModal))
        dispatch(actionLoading(false))
        return response.code
    } catch (error) {
        alert(error || error?.message)
    }
}

export const actionSaveListConfig = (payload) => ({
    type: Types.SAVE_LIST_CONFIG,
    payload,
})



export const actionLoading = (payload) => ({
    type: Types.SAVE_LOADINGS,
    payload,
})

export const actionSaveOpenHideSiderBar = (payload) => ({
    type: Types.SAVE_OPEN_HIDE_SIDERBAR,
    payload,
})


